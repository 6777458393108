<template>
	<div class="product">
		<banner :picS="bannerS"></banner>
		<div class="widthBox">
			<div class="productTitle">
			<titleOne en='PRODUCTS' title="商品紹介"></titleOne>
			</div>
			<div class="tabS acea-row row-center" :style="product_category.length <= 2 ? 'justify-content: space-around;':''">
				<div @click="proChange(index, item.id)" v-for="(item, index) in product_category" class="label" :key="index" :style="product_category.length <= 2 ? 'margin-right: 0px;':''"
					:class="proIndex == index ? 'active' : ''">{{ item.name }}</div>
			</div>
			<!-- <div class="tabS acea-row row-center">
				<div @click="subChange(index,item.id)" v-for="(item,index) in product_category[proIndex].sub_product_category" :key="index" class="label" :class="subIndex==index?'active':''">{{item.name}}</div>
			</div> -->
			<ul class="proS">
				<li class="acea-row" v-for="(item, index) in proList" :key="index">
					<el-image :src="item.main_figure" class="pic"></el-image>
					<div class="cont">
						<h3> 
							<template v-for="tt in item.index_remarks">{{ tt }}</template>
						</h3>
						<span></span>
						<p>{{ item.enproduct }}</p>
						<div class="info">{{ item.product }}</div>
						<router-link v-if="width >= 768" class="more" @mouseover.native="(active = true)"
							@mouseout.native="(active = false)" :to="'/pro_detail?id=' + item.id">
							クリックしてもっと見る
							<img class="icon" src="@/assets/icon_08.png" alt="">
						</router-link>
						<router-link v-if="width <= 768" class="more" :to="'/pro_detail?id=' + item.id">
							クリックしてもっと見る
						</router-link>
					</div>
				</li>
			</ul>
			<el-pagination v-if="total > 10" :page-size="pageSize" :current-page="page" @current-change="joinChange"
				layout="prev, pager, next" :total="total"></el-pagination>
		</div>

	</div>
</template>

<script>
import banner from '@/components/banner.vue'
import titleOne from '@/components/titleOne.vue'
export default {
	name: 'product',
	components: {
		banner, titleOne
	},
	computed: {
		bannerS() {
			return this.$store.state.bannerS
		},
	},
	data: function () {
		return {
			proIndex: 0,
			subIndex: 0,
			proList: [],
			category: [],
			page: 1,
			pageSize: 10,
			total: 1,
			width: 1920,
			product_category: [],
			category_id: 0,
			active: false
		}
	},
	created() {
		this.get_category();
		this.width = window.innerWidth
	},
	mounted() {
		this.$nextTick(function () {
			if (this.$route.query.to != undefined || this.$route.query.to != null) {
				setTimeout(() => {
					document.getElementById(this.$route.query.to).scrollIntoView()
				}, 500)
			} else {
				document.body.scrollIntoView()
			}
		})
	},
	methods: {
		getData(id) {
			this.$api.get('home/index/productCenter', {
				page: this.page,
				pageSize: this.pageSize,
				category_id: this.category_id
			}).then(res => {
				this.proList = res.data.productList
				this.category = res.data.product_category
				this.total = res.data.total
				// console.log('home/index/productCenter-------',res)
			})

		},
		get_category() {
			this.$api.get('home/index/productCategory').then(res => {
				this.product_category = res.data.product_category
				this.category_id = res.data.product_category[0].id
				this.getData();
				// console.log('home/index/productCategory-------',res)
			})
		},
		proChange(index, id) {
			if (this.proIndex == index) return
			this.proIndex = index
			this.subIndex = 0
			this.category_id = this.product_category[index].id
			this.proList = []
			this.getData()
		},
		subChange(index, id) {
			if (this.subIndex == index) return
			this.subIndex = index
			this.category_id = id
			this.page = 1
			this.proList = []
			this.getData()
		},
		joinChange(e) {
			this.page = e
			this.getData()
		}
	}
}
</script>

<style lang="scss" scoped="scoped">
.product {
	width: 100%;

	.productTitle {
		margin-top: 60px;
	}

	.el-pagination {
		margin: 50px auto 0;
		text-align: center;

		button {
			line-height: 40px;
			height: 40px;
			padding: 0 8px;
			font-weight: 500;
			background-color: rgba($color: #1D2A55, $alpha: 1);
			color: #FFF;

			i {
				font-size: 24px;
			}

			&:disabled {
				color: #1D2A55;
				background-color: rgba($color: #1D2A55, $alpha: 0.1);
				cursor: not-allowed;
			}
		}

		li {
			line-height: 40px;
			height: 40px;
			padding: 0 8px;
			font-size: 24px;
			margin: 0 5px;
			font-weight: 500;
			color: rgba($color: #1D2A55, $alpha: 0.4);

			&.active {
				color: rgba($color: #1D2A55, $alpha: 1);
				border: 1px solid #1D2A55;
			}
		}
	}

	.tabS {
		flex-wrap: wrap;
		justify-content: flex-start;

		.label {
			width: 400px;
			height: 80px;
			line-height: 80px;
			text-align: center;
			box-sizing: border-box;
			color: #1D2A55;
			border: 1px solid #1D2A55;
			font-size: 32px;
			margin-right: 100px;
			margin-top: 50px;
			cursor: pointer;

			&.active {
				color: #FFF;
				border: 1px solid #1D2A55 !important;
				background: #1D2A55 !important;
			}
		}

		.label:nth-child(3n) {
			margin-right: 0px;
		}
	}

	.proS {
		li {
			border: 1px solid #1D2A55;
			border-radius: 50px;
			overflow: hidden;
			margin-top: 50px;
		}

		.pic {
			width: 820px;
		}

		.cont {
			width: 538px;
			padding: 180px 50px 30px 50px;
			box-sizing: border-box;

			h3 {
				font-size: 40px;
				line-height: 64px;
				color: #272E4D;
			}

			span {
				width: 60px;
				height: 4px;
				background: #8A8D99;
				display: block;
				margin-top: 40px;
			}

			p {
				font-size: 18px;
				color: #8A8D99;
				margin-top: 25px;
			}

			.info {
				font-size: 24px;
				color: #666;
				margin-top: 18px;
			}

			.more {
				display: inline-block;
				// width:160px;
				padding: 0 30px;
				height: 50px;
				border: 1px solid #272E4D;
				color: #8A8D99;
				text-align: center;
				line-height: 50px;
				font-size: 18px;
				margin-top: 100px;
				position: relative;
				cursor: pointer;

				.icon {
					position: absolute;
					top: 50%;
					right: -54px;
					width: 77px;
					transform: translate(0, -50%);
				}
			}

			// .more:hover {
			// 	border: 1px solid #B90F0F !important;
			// 	color: #B90F0F !important;
			// }
		}
	}
}

@media screen and(max-width:1400px) {
	.product {
		.tabS {
			.label {
				width: calc((1200px - (100px*2)) /3);
				margin-right: 100px;
				height: 72px;
				font-size: 28px;
				line-height: 72px;
			}
		}

		.proS {
			.pic {
				width: 55%;
			}

			.cont {
				width: 45%;
				padding: 80px 30px 30px 30px;
			}
		}
	}
}

@media screen and(max-width:1200px) {
	.product {
		.tabS {
			.label {
				width: calc((1000px - (100px*2)) /3);
				margin-right: 100px;
				height: 60px;
				font-size: 24px;
				line-height: 60px;
			}
		}

		.proS {
			.pic {
				width: 55%;
			}

			.cont {
				width: 45%;
				padding: 60px 30px 30px 30px;

				h3 {
					font-size: 32px;
				}

				p {
					font-size: 16px;
				}

				.info {
					font-size: 20px;
				}

				.more {
					margin-top: 60px;
				}
			}
		}
	}
}

@media screen and(max-width:1200px) {
	.product {
		.productTitle {
			margin-top: 20px;
		}

		.tabS {
			.label {
				font-size: 14px;
				height: 36px;
				width: calc((1000px - (100px*2)) /3);
				margin-right: 100px;
				line-height: 36px;
				// margin-left:10px;
				margin-top: 20px;

				&:first-child {
					margin-left: 0;
				}
			}
		}

		.proS {
			li {
				margin-top: 20px;
				border-radius: 10px;

				.pic {
					height: max-content;
				}

				.cont {
					padding: 30px 10px 10px;

					h3 {
						font-size: 14px;
						line-height: 20px;
					}

					span {
						margin-top: 12px;
					}

					p {
						margin-top: 10px;
						font-size: 12px;
					}

					.info {
						margin-top: 0px;
						font-size: 12px;
					}

					.more {
						margin-top: 10px;
						height: 24px;
						line-height: 24px;
						width: 60px;
						font-size: 12px;

						img {
							display: none;
						}
					}
				}
			}
		}
	}
}

@media screen and(max-width:768px) {
	.product {
		padding: 0 16px;

		.productTitle {
			margin-top: 20px;
		}

		.tabS {
			.label {
				margin-right: 0;
			}

			.label:nth-child(2n+0) {

				margin-left: 20px;
			}

			justify-content: center;
		}

		.proS {
			.acea-row {
				border: none;
			}

			.pic {
				width: 100%;
			}

			.cont {
				width: 100%;
			}

			li {


				.cont {

					.more {
						width: 100%;
						height: 32px;
						opacity: 1;
						background: none;
						line-height: 32px;
						font-size: 12px;
						box-sizing: border-box;
					}
				}
			}
		}
	}

}

@media screen and(max-width:620px) {
	.product {
		padding: 0 16px;

		.productTitle {
			margin-top: 20px;
		}

		.tabS {
			.label {
				margin-left: 0;
			}
			
			.label:nth-child(2n+0) {

			margin-left: 0px;
			}
		}
	}

}</style>